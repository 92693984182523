//
//  Tooltip
//

// Base
.tooltip {
  .tooltip-inner {
    box-shadow: $tooltip-box-shadow;
  }

  &.tooltop-auto-width {
    .tooltip-inner {
      white-space: nowrap;
      max-width: none;
    }
  }

  // Dark Theme
  &.tooltip-dark {
    @include tooltip-theme($dark, $dark-inverse, $dark);
  }
}

.MuiTooltip-tooltip {
  color: #fff;
  padding: 4px 8px;
  font-size: 0.825rem !important;
  font-weight: 500;
  line-height: 1.4em;
  border-radius: 4px;
  background-color: #181c32 !important;
}