
//------- {{ Variables }} -------//

$branchColor: #2e6ba7;

$horizontal-gutter: 100px;
$border-radius: 10px;

$entry-min-height: 60px;

$label-width: 150px;
$label-height: 30px;
$label-border-radius: 5px;


//------- {{ Styles }} -------//
.profile-timeline{
  #wrapper {
    position: relative;
    overflow-x: auto;
  }
  
  .branch {
    position: relative;
    margin-left: $horizontal-gutter + $label-width;
    &:before {
      content: "";
      width: $horizontal-gutter / 2;
      border-top: 2px solid $branchColor;
      position: absolute;
      left: -$horizontal-gutter;
      top: 50%;
      margin-top: 1px;
    }
  }
  
  .entry {
    position: relative;
    min-height: $entry-min-height;
    &:before {
      content: "";
      height: 100%;
      border-left: 2px solid $branchColor;
      position: absolute;
      left: -($horizontal-gutter / 2);
    }
    &:after {
      content: "";
      width: $horizontal-gutter / 2;
      border-top: 2px solid $branchColor;
      position: absolute;
      left: -($horizontal-gutter / 2);
      top: 50%;
      margin-top: 1px;
    }
    &:first-child {
      &:before {
        width: $border-radius;
        height: 50%;
        top: 50%;
        margin-top: 2px;
        border-radius: $border-radius 0 0 0;
      }
      &:after {
        height: $border-radius;
        border-radius: $border-radius 0 0 0;
      }
    }
    &:last-child {
      &:before {
        width: $border-radius;
        height: 50%;
        border-radius: 0 0 0 $border-radius;
      }
      &:after {
        height: $border-radius;
        border-top: none;
        border-bottom: 2px solid $branchColor;
        border-radius: 0 0 0 $border-radius;
        margin-top: -$border-radius + 1px;
      }
    }
    &.sole {
      &:before {
        display: none;
      }
      &:after {
        width: $horizontal-gutter / 2;
        height: 0;
        margin-top: 1px;
        border-radius: 0;
      }
    }
  }
  
  .label {
    display: block;
    min-width: $label-width;
    padding: 5px 10px;
    line-height: $label-height - 5px * 2;
    text-align: center;
    border: 2px solid $branchColor;
    border-radius: $label-border-radius;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -($label-height / 2);
  }
}